import { Translation } from 'gatsby-plugin-react-i18next';
import { isMobile } from 'react-device-detect';
import srcIconLinkedin from '../../assets/icon-linkedin.svg';
import srcIconTwitter from '../../assets/icon-twitter.svg';
import logoVertical from '../../assets/logotype-white-vertical.svg';
import logo from '../../assets/logotype-white.svg';
import styles from './Footer.module.scss';

const Footer = ({ t }: { t: Translation }) => {
  return (
    <section className={styles.footer}>
      <footer className="wrapper">
        <div className={styles.top}>
          <div className={styles.left}>
            {isMobile ? (
              <img
                src={logoVertical}
                height={80}
                alt="Smartpay（スマートペイ）"
              />
            ) : (
              <img src={logo} height={23} alt="Smartpay（スマートペイ）" />
            )}
            <nav className={styles.sns}>
              <a
                href="https://twitter.com/smartpayjapan"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={srcIconTwitter} width={24} alt="Smartpay | Twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/smartpay-co/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={srcIconLinkedin}
                  width={24}
                  alt="Smartpay | Linkedin"
                />
              </a>
            </nav>
          </div>
          <div className={styles.right}>
            <nav className={styles.forConsumer}>
              <h4>{t('for-consumers')}</h4>
              <a href="https://smartpay.co">{t('top-page')}</a>
              <a href="https://smartpay.co/faq/consumer/">
                {t('faq-for-consumers')}
              </a>
            </nav>
            <nav className={styles.forMerchant}>
              <h4>{t('for-merchants')}</h4>
              <a href="https://smartpay.co/merchant/">{t('solution')}</a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.smartpay.co"
              >
                {t('for-developers')}
              </a>
              <a href="https://smartpay.co/faq/merchant/">{t('faq')}</a>
            </nav>
            <nav className={styles.about}>
              <h4>{t('about-smartpay')}</h4>
              <a href="https://smartpay.co/about/">{t('about')}</a>
              {/* <NavLink to="/press/">{t('press')}</NavLink>
              <NavLink to="/security/">{t('security')}</NavLink> */}
              <a href="https://smartpay.co/newsroom/">{t('newsroom')}</a>
              <a href="https://smartpay.co/contact-us/">
                {t('menu-contact-us')}
              </a>
            </nav>
          </div>
        </div>
        <div className={styles.bot}>
          &copy; {new Date().getFullYear()} {t('company-name-detail')}
        </div>
      </footer>
    </section>
  );
};

export default Footer;
