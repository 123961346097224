const currentYear = new Date().getFullYear();

const jdonLd = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://terms.smartpay.co',
  image: '/logo.svg',
  headline: 'Smartpay',
  author: '@smartpay',
  copyrightHolder: 'Smartpay K.K.',
  copyrightYear: currentYear,
  audience: 'consumer',
  encodings: 'UTF-8',
  keywords: '',
  description: '',
  inLanguage: 'ja',
  locationCreated: {
    address: {
      addressCountry: 'Japan',
      addressRegion: 'Tokyo',
      postalCode: '1050003',
      streetAddress: '港区西新橋2丁目19番5号 カザマビル6階',
    },
  },
};

export default jdonLd;
