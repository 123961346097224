import { Link } from 'gatsby-plugin-react-i18next';
import logo from '../../assets/logo.svg';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <div className={styles.headerWrapper}>
      <header>
        <Link to="/">
          <img src={logo} width={215.21} height={32} alt="Smartpay | Terms" />
        </Link>
      </header>
    </div>
  );
};

export default Header;
