export const titlePathnameMap: { [key: string]: string } = {
  '/': 'トップ',
  '/privacy': '個人情報取扱条項',
  '/tnc': '利用規約',
  '/en/privacy': 'Privacy',
  '/en/tnc': 'Terms and Conditions',
};

export const LANG_EN = 'en';
export const LANG_JA = 'ja';
