import { useLocation } from '@reach/router';
import cx from 'classnames';
import { Translation } from 'gatsby-plugin-react-i18next';
import { ReactElement, ReactNode } from 'react';
import { titlePathnameMap } from '../../constants';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import SEO from '../SEO';
import './Layout.scss';

const Layout = ({
  className = '',
  isMerchant = false,
  children,
  t,
  i18n,
  SEOProps = {},
}: {
  className?: string;
  isMerchant?: boolean;
  children: ReactNode;
  t: Translation;
  i18n: import('i18next').i18n;
  SEOProps?: object;
}): ReactElement => {
  const { pathname } = useLocation();

  return (
    <div className={cx('root-wrapper')}>
      <SEO
        title={titlePathnameMap[pathname] || ''}
        pathname={pathname}
        lang={i18n.language}
        audience={isMerchant ? 'merchant' : 'consumer'}
        {...SEOProps}
      />
      <Header />
      <div className="main-wrapper">
        <main className={className}>{children}</main>
      </div>
      <Footer t={t} />
    </div>
  );
};

export default Layout;
