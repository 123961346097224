import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { graphql } from 'gatsby';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout/Layout';
import renderOptions from '../utils/doc-render-options';
import styles from './legal.module.scss';

export const privacyPageQuery = graphql`
  query PrivacyPageQuery($language: String!, $dev: Boolean!) {
    contentful {
      legalDocument(id: "Rmjwp57wEXQRi4c2RyZZ1", locale: "ja", preview: $dev) {
        title
        content {
          json
        }
        lastUpdated
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PrivacyPage = ({
  data,
}: {
  data: {
    contentful: {
      legalDocument: {
        title: string;
        content: { json: Document };
        lastUpdated: Date;
      };
    };
    locales: Object;
  };
}) => {
  const { t, i18n } = useI18next();
  const {
    contentful: {
      legalDocument: {
        title,
        content: { json },
        lastUpdated,
      },
    },
  } = data;

  const content = documentToReactComponents(json, renderOptions);

  return (
    <Layout t={t} i18n={i18n}>
      <nav className={styles.innerTopNav}>
        <Link to="/">{t('back')}</Link>
      </nav>
      <article className={styles.article}>
        <time>
          {t('last-updated')}:{' '}
          {new Date(lastUpdated).toLocaleDateString('ja-JP')}
        </time>
        <h1>{title}</h1>
        {content}
      </article>
    </Layout>
  );
};

export default PrivacyPage;
