import { graphql } from 'gatsby';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout/Layout';
import styles from './index.module.scss';

interface Props {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
      };
    };
  };
}

export const indexPageQuery = graphql`
  query IndexPageQuery($language: String!) {
    site {
      siteMetadata {
        name
        tagline
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const IndexPage: React.FC<Props> = () => {
  // const { name } = data.site.siteMetadata;
  const { t, i18n } = useI18next();

  return (
    <Layout t={t} i18n={i18n}>
      <section className={styles.main}>
        <div className={styles.wrapper}>
          <h1>{t('top-h1')}</h1>
          <section className={styles.content}>
            <nav>
              <h2>{t('tnc')}</h2>
              <Link to="/tnc/">Smartpay{t('tnc')}</Link>
            </nav>
            <nav>
              <h2>{t('privacy-policy')}</h2>
              <Link to="/privacy/">{t('privacy-policy')}</Link>
            </nav>
            <nav>
              <h2>{t('smartpay-bank-direct-tnc')}</h2>
              <Link to="/bank-direct/">{t('smartpay-bank-direct-tnc')}</Link>
            </nav>
          </section>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
